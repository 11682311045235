// 跨域问题链接
import axios from "axios";

const request = axios.create({
    baseURL: '/api',
    timeout: 50000
})

export function getFile (url) {
    return axios.request({
        url: url,
        method: 'get',
        responseType: 'blob'
    })
}

export default request