import Vue from "vue";
import Vuex from 'vuex'
import router from "@/router";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        //类型维护
        //性别
        sexs: [
            {label: '男', value: '男'},
            {label: '女', value: '女'},
        ],
        //角色
        roles: [
            {label: '超级管理员', value: 1},
            {label: '管理人', value: 2},
            {label: '法院', value: 3},
            {label: '债权人', value: 4},
        ],
        loginRoles: [
            {label: '超级管理员', value: 1},
            {label: '管理人', value: 2},
            {label: '法院', value: 3},
        ],
        roles1: [
            {label: '管理人', value: 2},
            {label: '法院', value: 3},
        ],
        roles2: [
            {label: '超级管理员', value: 1},
            {label: '法院', value: 3},
        ],
        roles3: [
            {label: '超级管理员', value: 1},
            {label: '管理人', value: 2},
        ],
        //管理人级别
        managerLevels: [
            {label: '一级管理人', value: '一级管理人'},
            {label: '二级管理人', value: '二级管理人'},
            // {label: '三级管理人', value: '三级管理人'},
            // {label: '未分级管理人', value: '未分级管理人'},
            // {label: '清算组管理人', value: '清算组管理人'},
        ],
        // 案件类型
        caseTypes: [
            {label: '破产申请审查案件', value: '破产申请审查案件'},
            {label: '破产案件', value: '破产案件'},
            {label: '破产上诉案件', value: '破产上诉案件'},
            {label: '破产监督案件', value: '破产监督案件'},
            {label: '强制清算申请审查案件', value: '强制清算申请审查案件'},
            {label: '强制清算上诉案件', value: '强制清算上诉案件'},
            {label: '强制清算监督案件', value: '强制清算监督案件'},
            {label: '强制清算案件', value: '强制清算案件'},
        ],
        caseStatus:[
            {label: '在执行', value: '在执行'},
            {label: '已结案', value: '已结案'},
        ],
        //破产类型
        bankruptcyTypes: [
            {label: '企业破产', value: '企业破产'},
            {label: '个人破产', value: '个人破产'}
        ],
        //诉讼地位
        lawsuitStatus: [
            {value: '申请人', label: '申请人'},
            {value: '被申请人', label: '被申请人'}
        ],
        //当时人类型 （债权人类型）
        partyTypes: [
            {value: '自然人', label: '自然人'},
            {value: '机构', label: '机构'},
        ],
        //短信类型
        smsType: [
            {value: '会议提醒', label: '会议提醒'},
            {value: '签到提醒', label: '签到提醒'},
            {value: '表决提醒', label: '表决提醒'},
        ],
        //成功失败
        flagType: [
            {value: '成功', label: '成功'},
            {value: '失败', label: '失败'},
        ],
        //证件类型
        idCardTypes: [
            {value: '中华人民共和国身份证', label: '中华人民共和国身份证'},
            {value: '军官证', label: '军官证'},
            {value: '护照', label: '护照'},
            {value: '其他', label: '其他'},
        ],
        idTypes: [
            {value: '中华人民共和国身份证', label: '中华人民共和国身份证', flag: '1'},
            {value: '军官证', label: '军官证', flag: '1'},
            {value: '护照', label: '护照', flag: '1'},
            {value: '其他', label: '其他', flag: '1'},
            {value: '营业执照', label: '营业执照', flag: '2'},
            {value: '统一社会信用代码', label: '统一社会信用代码', flag: '2'},
            {value: '事业法人登记证', label: '事业法人登记证', flag: '2'},
            {value: '其他', label: '其他', flag: '2'},
            {value: '无', label: '无', flag: '2'},
        ],
        //文书类型
        bookTypes: [
            {value: '裁定书', label: '裁定书'},
            {value: '决定书', label: '决定书'},
            {value: '通知书', label: '通知书'},
            {value: '公告', label: '公告'},
            {value: '复函', label: '复函'},
            {value: '其他', label: '其他'}
        ],
        banks: [
            {value: '中国银行', label: '中国银行'},
            {value: '建设银行', label: '建设银行'}
        ],
        //接管状态
        takeoverStatus: [
            {value: '已接管', label: '已接管'},
            {value: '未接管', label: '未接管'}
        ],
        //标的物状态
        matterStatus: [
            {value: '未接管', label: '未接管'},
            {value: '已接管', label: '已接管'},
            {value: '已评估', label: '已评估'},
            {value: '已成交', label: '已成交'},
            {value: '未成交', label: '未成交'},
            {value: '完成交割', label: '完成交割'},
            {value: '已流拍', label: '已流拍'},
            {value: '待发布', label: '待发布'},
            {value: '即将开始', label: '即将开始'},
            {value: '正在进行', label: '正在进行'},
            {value: '已结束', label: '已结束'},
            {value: '已中止', label: '已中止'},
            {value: '已撤回', label: '已撤回'}
        ],
        //标的物分类
        matterTypes: [
            {value: '住宅用地', label: '住宅用地'},
            {value: '土地', label: '土地'},
            {value: '资产', label: '资产'},
            {value: '机动车', label: '机动车'},
            {value: '林权', label: '林权'},
            {value: '矿权', label: '矿权'},
            {value: '工程', label: '工程'},
            {value: '其他', label: '其他'},
            {value: '无形资产', label: '无形资产'},
            {value: '股权', label: '股权'},
            {value: '船舶', label: '船舶'},
            {value: '其他用房', label: '其他用房'},
            {value: '航空交通', label: '航空交通'},
            {value: '古玩字画', label: '古玩字画'},
            {value: '机器设备', label: '机器设备'},
            {value: '海域', label: '海域'},
            {value: '工业用房', label: '工业用房'},
            {value: '珠宝首饰', label: '珠宝首饰'},
            {value: '商业用房', label: '商业用房'},
            {value: '债权', label: '债权'}
        ],
        // 计算率
        countRates:[
            {value: '10', label: '10%'},
            {value: '20', label: '20%'},
            {value: '30', label: '30%'},
            {value: '40', label: '40%'},
            {value: '50', label: '50%'},
            {value: '60', label: '60%'},
            {value: '70', label: '70%'},
            {value: '80', label: '80%'},
            {value: '90', label: '90%'},

        ],
        //债权类型标的物分类
        matterSorts: [
            {value: '普通债权', label: '普通债权'},
            {value: '有财产担保债权', label: '有财产担保债权'},
            {value: '其他法定优选债权', label: '其他法定优选债权'},
            {value: '税款债权', label: '税款债权'},
            {value: '职工债权', label: '职工债权'}
        ],
        isEstimate: [
            {value: '未评估', label: '未评估'},
            {value: '已评估', label: '已评估'}
        ],
        //是否
        isNots: [
            {value: '是', label: '是'},
            {value: '否', label: '否'}
        ],
        //处理方式
        disposalTypes: [
            {value: '线下处置', label: '线下处置'},
            {value: '已线上处置', label: '已线上处置'},
            {value: '需发拍处置', label: '需发拍处置'}
        ],
        //签到状态
        signStatus: [
            {value: '未签到', label: '未签到'},
            {value: '已签到', label: '已签到'},
        ],
        //投票状态
        voteStatus: [
            {value: '同意', label: '同意'},
            {value: '反对', label: '反对'},
            {value: '弃权', label: '弃权'},
            {value: '未投票', label: '未投票'},
        ],
        //拍卖阶段
        auctionPhases: [
            {value: '一次拍卖', label: '一次拍卖'},
            {value: '二次拍卖', label: '二次拍卖'},
            {value: '重新拍卖', label: '重新拍卖'},
            {value: '变卖', label: '变卖'},
            {value: '再次拍卖', label: '再次拍卖'}
        ],
        //附件类型
        annexTypes: [
            {value: '附件', label: '附件'},
            {value: '授权材料', label: '授权材料'}
        ],
        //竞买人资质
        qualifications: [
            {value: '无需求', label: '无需求'},
            {value: '有需求，不需审核', label: '有需求，不需审核'},
            {value: '有需求，需要审核', label: '有需求，需要审核'}
        ],
        materialTypes: [
            {value: '图片', label: '图片'},
            {value: '视频', label: '视频'},
            {value: '文件', label: '文件'}
        ],
        //发拍模板
        templates: [
            {value: '资产-调查评估表模板', label: '资产-调查评估表模板'},
            {value: '矿权-调查评估表模板', label: '矿权-调查评估表模板'},
            {value: '林权-调查评估表模板', label: '林权-调查评估表模板'},
            {value: '土地-调查评估表模板', label: '土地-调查评估表模板'},
            {value: '无形资产-调查评估表模板', label: '无形资产-调查评估表模板'},
            {value: '船舶-调查评估表模板', label: '船舶-调查评估表模板'},
            {value: '房产-调查评估表模板', label: '房产-调查评估表模板'},
            {value: '股权-调查评估表模板', label: '股权-调查评估表模板'},
        ],
        //审核状态
        examineStatus: [
            {value: '部分确认', label: '部分确认'},
            {value: '全部确认', label: '全部确认'},
            {value: '不予确认', label: '不予确认'},
            {value: '不通过', label: '不通过'},
            {value: '暂缓确认', label: '暂缓确认'},
            {value: '未确认', label: '未确认'}
        ],
        meetingExamine:[
            {value: '未审核', label: '未审核'},
            {value: '审核通过', label: '审核通过'},
            {value: '审核不通过', label: '审核不通过'},
        ],
        //审核阶段
        examines: [
            {value: '待审核', label: '待审核'},
            {value: '已审核', label: '已审核'}
        ],
        //债权人
        debtOperates: [
            {value: '已提交', label: '已提交'},
            {value: '撤回修改', label: '撤回修改'},
            // {value: '补充材料', label: '补充材料'},
            // {value: '重新提交', label: '重新提交'},
            {value: '提交异议', label: '提交异议'}
        ],
        //申报方式
        reportWays: [
            {value: '线上申报', label: '线上申报'},
            {value: '线下申报', label: '线下申报'}
        ],
        //申报状态
        reportStatus: [
            {value: '否', label: '债权申报开启'},
            {value: '是', label: '债权申报关闭'}
        ],
        //债权审核模式
        debtReviewModes: [
            {value: '仅金额审核', label: '仅金额审核'},
            {value: '二轮审核', label: '二轮审核'},
            {value: '三轮审核', label: '三轮审核'}
        ],
        //参会人设置
        attendeeSettings: [
            {value: '更新债权金额后，重置签到投票结果（需债权人重新签到投票）', label: '更新债权金额后，重置签到投票结果（需债权人重新签到投票）'},
            {value: '更新债权金额后，不变更签到投票结果（不需要债权重新签到投票）', label: '更新债权金额后，不变更签到投票结果（不需要债权重新签到投票）'}
        ],
        //投票形式
        votingFormat: [
            {value: '单个投票项投票', label: '单个投票项投票'},
            {value: '多个投票项投票', label: '多个投票项投票'}
        ],
        //投票形式
        voteRules: [
            {value: '线上投票', label: '线上投票'},
            {value: '线下投票', label: '线下投票'}
        ],
        //汇总金额分母设置
        denominatorSettings: [
            {value: '债权类型总额', label: '债权类型总额'},
            {value: '固定金额', label: '固定金额'}
        ],
        //事务类型
        transactionTypes: [
            {label: '组织管理人团队', value: '组织管理人团队'},
            {label: '接管债务人', value: '接管债务人'},
            {label: '选定中介机构', value: '选定中介机构'},
            {label: '财产调查和管理', value: '财产调查和管理'},
            {label: '债权申报和审核', value: '债权申报和审核'},
            {label: '债权人会议', value: '债权人会议'},
            {label: '财产分配', value: '财产分配'},
            {label: '债权人注销', value: '债权人注销'}
        ],
        //事务状态
        transactionStatus: [
            {label: '进行中', value: '组织管理人团队'},
            {label: '待审批', value: '接管债务人'},
            {label: '已完成', value: '选定中介机构'},
            {label: '已驳回', value: '财产调查和管理'},
            {label: '超时', value: '债权申报和审核'},
            {label: '已撤销', value: '债权人会议'},
        ],
        //结案方式
        endTypes: [
            {label: '终结破产程序', value: '终结破产程序'},
            {label: '本院受理破产申请', value: '本院受理破产申请'},
            {label: '驳回破产申请', value: '驳回破产申请'},
            {label: '不予受理破产申请', value: '不予受理破产申请'},
            {label: '准予撤回破产申请', value: '准予撤回破产申请'},
            {label: '移送下级法院审理', value: '移送下级法院审理'},
        ],
        //结案案由
        endReasons: [
            {label: '破产清算', value: '破产清算'},
            {label: '破产重整', value: '破产重整'},
            {label: '破产和解', value: '破产和解'},
        ],

        //获取当前的路由路径以及名称
        wPath: {},
        //所有打开的路由
        openTab: [
            {name: "系统消息", path: "/pages/sysMsg"}
        ],
        // 激活状态，侧边导航和标签页的激活状态，初始为首页
        activePath: "/pages/sysMsg",

        //案件管理面包屑
        bdr: [
            // {name:'案件管理',path:'/admin/cases'}
        ],
        activeBdrPath: '',


    },
    mutations: {
        // 单机导航栏时添加标签页
        addTab(state, data) {
            let a = 0;
            if (state.openTab.indexOf(data) === -1) {
                for (let i = 0; i < state.openTab.length; i++) {
                    if (state.openTab[i].path === data.path) {
                        a += 1;
                    }
                }
                if (a === 0) {
                    state.openTab.push(data);
                    state.activePath = data.path;
                }
            }
        },
        //面包表头
        addBdr(state, data) {
            let b = 0;
            if (state.bdr.indexOf(data) === -1) {
                for (let i = 0; i < state.bdr.length; i++) {
                    if (state.bdr[i].name === data.name) {
                        b += 1;
                    }
                }
                if (b === 0) {
                    state.bdr.push(data);
                    state.activeBdrPath = data.path;
                }
            }
        },
        setPath(state) {
            state.wPath = sessionStorage.getItem("wPath")
        },
        out(state,data) {
            state.openTab=[];
            sessionStorage.clear();
            router.push({path:'/superLogin'});
            // if (data==1){
            //     sessionStorage.clear();
            //     router.push({path:'/superLogin'});
            // }else if (data==2){
            //     sessionStorage.clear();
            //     router.push({path:'/managerLogin'});
            // }else if (data==3){
            //     sessionStorage.clear();
            //     router.push({path:'/courtLogin'});
            // }
        }
    },
})
export default store