<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>

export default {
  name: 'App',
    mounted() {
        //第二种方法
        // if (window.performance.navigation.type === 1) {
        //     this.$router.push('/court/sysMsg')
        //     console.log("页面被刷新")
        // }else{
        //     console.log("首次被加载")
        // }
    }

}
</script>

<style lang="less">
#app {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}
</style>
