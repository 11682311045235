import CryptoJS from 'crypto-js'


const AES_KEY ='GQQ3LP1O5ROYDHAYCAPQXKFRSDRTH8BX';

// 加密
// keyStr 设置的长度要不小于14位
export function encryptAES(word) {
    let key = CryptoJS.enc.Utf8.parse(AES_KEY)
    let srcs = CryptoJS.enc.Utf8.parse(word)
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    return encrypted.toString()
}

// 解密
export function decryptAES(word) {
    var key = CryptoJS.enc.Utf8.parse(AES_KEY)
    var decrypt = CryptoJS.AES.decrypt(word, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    console.log(CryptoJS.enc.Utf8.stringify(decrypt).toString())
    return CryptoJS.enc.Utf8.stringify(decrypt).toString()
}
