const courtData=[
    {
        path: '/pages',
        name: '后台',
        flag: 0,
        role:3,icon: 'el-icon-set-up',
        component: () => import('@/views/Home'),
        children: [
            {
                path: '/pages/passWord',
                name: '修改密码',
                flag: 1,
                role:3,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/systemManagement/PassWord')
            },
            {
                path: '/pages/caseDetails',
                name: '案件详情',
                flag: 1,
                role:3,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/caseManagement/CaseDetails')
            },
            {
                path: '/pages/propertyInfo',
                name: '财产详情',
                flag: 1,
                role:3,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/caseManagement/PropertyInfo')
            },
            {
                path: '/pages/sendAction',
                name: '编辑发拍',
                flag: 1,
                role:3,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/caseManagement/SendAction')
            },
            {
                path: '/pages/casePayInfo',
                name: '案款监管详情',
                flag: 1,
                role:3,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/caseManagement/CasePayInfo')
            },
            {
                path: '/pages/debtInfo',
                name: '债权详情',
                flag: 1,
                role:3,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/caseManagement/DebtInfo')
            },
            {
                path: '/pages/taskInfo',
                name: '事务详情',
                flag: 1,
                role:3,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/dataStatistics/TaskInfo')
            },
            {
                path: '/pages/meeting',
                name: '会议详情',
                flag: 1,
                role: 3,
                component: () => import('@/views/pages/meetingManagement/MeetingDetails')
            },
        ]
    },
    {
        path: '/pages',
        name: '工作台',
        flag: 1,
        role:3,icon: 'el-icon-s-platform',
        component: () => import('@/views/Home'),
        children: [
            {
                path: '/pages/sysMsg',
                name: '系统消息',
                flag: 1,
                role:3,icon: 'el-icon-message-solid',
                component: () => import('@/views/pages/staging/SysMsg')
            },
            // {
            //     path: '/pages/agencyMsg',
            //     name: '代办消息',
            //     flag: 1,
            //     role:3,icon: 'el-icon-set-up',
            //     component: () => import('@/views/pages/staging/AgencyMsg')
            // }
        ]
    },
    {
        path: '/pages',
        name: '案件管理',
        flag: 1,
        role:3,icon: 'el-icon-set-up',
        component: () => import('@/views/Home'),
        children: [
            {
                path: '/pages/cases',
                name: '全部案件',
                flag: 1,
                role:3,icon: 'el-icon-s-management',
                component: () => import('@/views/pages/caseManagement/Cases')
            },
            {
                path: '/pages/propertyTake',
                name: '财产接管',
                flag: 1,
                role:3,icon: 'el-icon-money',
                component: () => import('@/views/pages/caseManagement/PropertyTake')
            },
            // {
            //     path: '/pages/casePayTake',
            //     name: '案款监管',
            //     flag: 1,
            //     role:3,icon: 'el-icon-set-up',
            //     component: () => import('@/views/pages/caseManagement/CasePayTake')
            // },
        ]
    },
    // {
    //     path: '/pages',
    //     name: '数据统计',
    //     flag: 1,
    //     role:3,icon: 'el-icon-set-up',
    //     component: () => import('@/views/Home'),
    //     children: [
    //         {
    //             path: '/pages/taskSummary',
    //             name: '任务汇总',
    //             flag: 1,
    //             role:3,icon: 'el-icon-set-up',
    //             component: () => import('@/views/pages/dataStatistics/TaskSummary')
    //         },
    //         {
    //             path: '/pages/census',
    //             name: '标的统计',
    //             flag: 1,
    //             role:3,icon: 'el-icon-set-up',
    //             component: () => import('@/views/pages/dataStatistics/Census')
    //         },
    //     ]
    // },
    {
        path: '/pages',
        name: '系统设置',
        flag: 1,
        role:3,icon: 'el-icon-s-tools',
        component: () => import('@/views/Home'),
        children: [
            // {
            //     path: '/pages/organ',
            //     name: '管理人库',
            //     flag: 1,
            //     role:3,icon: 'el-icon-set-up',
            //     component: () => import('@/views/pages/systemManagement/Organ')
            // },
            {
                path: '/pages/volunteerService',
                name: '志愿者服务',
                flag: 1,
                role:3,icon: 'el-icon-thumb',
                component: () => import('@/views/pages/systemManagement/Volunteer')
            },
            // {
            //     path: '/pages/systemLog',
            //     name: '系统日志',
            //     flag: 1,
            //     role:3,icon: 'el-icon-set-up',
            //     component: () => import('@/views/pages/systemManagement/SystemLog')
            // },
        ]
    },
];

export default courtData;