const managerData=[
    {
        path: '/manager',
        name: '工作台',
        flag: 0,
        role:2,icon: 'el-icon-set-up',
        component: () => import('@/views/Manager'),
        children: [
            {
                path: '/manager/passWord',
                name: '修改密码',
                flag: 1,
                role:1,
                component: () => import('@/views/pages/systemManagement/PassWord')
            },
            {
                path: '/manager/staging',
                name: '工作台',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/staging/Staging')
            },
            {
                path: '/manager/liveConsole',
                name: '直播控制台',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/meetingManagement/LiveConsole')
            },
            {
                path: '/manager/onlineDeclaration',
                name: '线上债权申报',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/staging/OnlineDeclaration')
            },
            {
                path: '/manager/onlineMeeting',
                name: '债权人会议',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/staging/OnlineMeeting')
            },
            {
                path: '/manager/sysMsg',
                name: '全部消息',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/staging/SysMsg')
            }
        ]
    },
    {
        path: '/manager',
        name: '案件管理',
        flag: 0,
        role:2,icon: 'el-icon-set-up',
        component: () => import('@/views/Manager'),
        children: [
            {
                path: '/manager/cases',
                name: '案件管理',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/caseManagement/Cases'),
            },
            {
                path: '/manager/selectCase',
                name: '遴选案件',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/caseManagement/SelectCase')
            },
            {
                path: '/manager/caseDetails',
                name: '案件详情',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/caseManagement/CaseDetails')
            },
            {
                path: '/manager/caseInfo',
                name: '基本信息',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/caseManagement/CaseInfo')
            },
            {
                path: '/manager/propertyInfo',
                name: '财产详情',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/caseManagement/PropertyInfo')
            },
            {
                path: '/manager/sendAction',
                name: '发拍信息',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/caseManagement/SendAction')
            },
            {
                path: '/manager/debt',
                name: '线上债权申报',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/caseManagement/Debt')
            },
            {
                path: '/manager/debtInfo',
                name: '债权详情',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/caseManagement/DebtInfo')
            },
            {
                path: '/manager/declaration',
                name: '申报配置',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/declareConfiguration/DeclareConfiguration')
            },
            {
                path: '/manager/creditorsMeeting',
                name: '全部会议',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/caseManagement/CreditorsMeeting')
            },
            {
                path: '/manager/meeting',
                name: '会议详情',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/meetingManagement/MeetingDetails')
            },
        ]
    },
    {
        path: '/manager',
        name: '资产管理',
        flag: 0,
        role:2,icon: 'el-icon-set-up',
        component: () => import('@/views/Manager'),
        children: [
            {
                path: '/manager/propertyTake',
                name: '财产接管',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/caseManagement/PropertyTake')
            },
            {
                path: '/manager/casePayTake',
                name: '案款监管',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/assetManagement/CasePayTake')
            },
            {
                path: '/manager/census',
                name: '标的统计',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/assetManagement/Census')
            },
            {
                path: '/manager/bidderReview',
                name: '标的统计',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/assetManagement/BidderReview')
            },
        ]
    },
    {
        path: '/manager',
        name: '服务平台',
        flag: 0,
        role:2,icon: 'el-icon-set-up',
        component: () => import('@/views/Manager'),
        children: [
            {
                path: '/manager/service',
                name: '服务平台',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/servicePlatform/Service')
            },
            {
                path: '/manager/order',
                name: '订单管理',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/servicePlatform/OrderManagement'),
            },
            {
                path: '/manager/task',
                name: '任务管理',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/servicePlatform/TaskManagement')
            },
            {
                path: '/manager/serviceProvider',
                name: '服务商管理',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/servicePlatform/ServiceProviderManagement')
            },
            {
                path: '/manager/volunteerService',
                name: '志愿者服务',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/systemManagement/Volunteer')
            },
        ]
    },
    {
        path: '/manager',
        name: '系统管理',
        flag: 0,
        role:2,icon: 'el-icon-set-up',
        component: () => import('@/views/Manager'),
        children: [
            {
                path: '/manager/system',
                name: '系统管理',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/systemManagement/System')
            },
            {
                path: '/manager/systemSettings',
                name: '系统设置',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/systemManagement/SystemSettings'),
            },
            {
                path: '/manager/systemLog',
                name: '系统日志',
                flag: 0,
                role:2,icon: 'el-icon-set-up',
                component: () => import('@/views/pages/systemManagement/SystemLog')
            },
        ]
    },
];

export default managerData;